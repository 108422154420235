<template>
  <div class="app-container">
    <div class="mytabel table-sm mr-0 ml-0 p-0">
      <div class="row table-sm mr-0 ml-0 p-0 mb-3 width-100">
        <div class="col-3 p-0 align-self-center font-weight-bold">
          <h5 class="d-inline mr-2 font-weight-bold">
            {{ $t("message.users") }}
          </h5>
          <i class="el-icon-refresh cursor-pointer"></i>
        </div>
        <div
          class="
            col-9
            align-items-center align-self-center
            text-right
            pr-0
            d-flex
            justify-content-end
          "
        >
          <div class="text-center d-flex sorddata">
            <el-input
              class="mr-2"
              size="mini"
              :placeholder="$t('message.search')"
              prefix-icon="el-icon-search"
              v-model="filterForm.search"
              clearable
            ></el-input>
          </div>
          <el-button
            class="mr-2"
            size="mini"
            @click="tabAddDoctor = true"
            icon="el-icon-circle-plus-outline"
          >
            {{ $t("message.create") }}
          </el-button>
          <export-excel
            class="btn excel_btn mr-2"
            :data="list"
            worksheet="Пользователи"
            name="Пользователи.xls"
          >
            <el-button size="mini" icon="el-icon-document-delete">
              Excel
            </el-button>
          </export-excel>
          <el-dropdown class="mr-2">
            <el-button size="mini" icon="el-icon-setting">
              <i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item v-for="(column, index) in columns" :key="index">
                <el-checkbox
                  :checked="column.show"
                  @change="column.show = !column.show"
                  >{{ column.title }}</el-checkbox
                >
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>

      <table
        class="table table-bordered table-hover mr-0 ml-0 p-0"
        v-loading="loadingData"
      >
        <thead>
          <tr>
            <th v-if="columns.id.show">{{ columns.id.title }}</th>
            <th v-if="columns.name.show">{{ columns.name.title }}</th>
            <th v-if="columns.IDcod.show">{{ columns.IDcod.title }}</th>
            <th v-if="columns.created_at.show">
              {{ columns.created_at.title }}
            </th>
            <th v-if="columns.phone.show">{{ columns.phone.title }}</th>
            <th v-if="columns.polMW.show">{{ columns.polMW.title }}</th>
            <th v-if="columns.role_id.show">{{ columns.role_id.title }}</th>
            <th v-if="columns.inn_cod.show">{{ columns.inn_cod.title }}</th>
            <th v-if="columns.inn_cod.show">{{ columns.inn_cod.title }}</th>
            <th v-if="columns.room_number.show">
              {{ columns.room_number.title }}
            </th>
            <th v-if="columns.email.show">{{ columns.email.title }}</th>
            <th v-if="columns.settings.show">{{ columns.settings.title }}</th>
          </tr>

          <tr>
            <th v-if="columns.id.show">
              <el-input
                clearable
                size="mini"
                v-model="filterForm.id"
                class="id_input"
                :placeholder="columns.id.title"
              ></el-input>
            </th>
            <th v-if="columns.name.show">
              <el-input
                clearable
                size="mini"
                v-model="filterForm.name"
                :placeholder="columns.name.title"
              >
              </el-input>
            </th>
            <th v-if="columns.IDcod.show">
              <el-input
                clearable
                size="mini"
                v-model="filterForm.IDcod"
                :placeholder="columns.IDcod.title"
              >
              </el-input>
            </th>
            <th v-if="columns.created_at.show">
              <el-date-picker
                :placeholder="columns.created_at.title"
                v-model="filterForm.created_at"
                size="mini"
                :format="'dd.MM.yyyy'"
                :value-format="'dd.MM.yyyy'"
              >
              </el-date-picker>
            </th>
            <th v-if="columns.phone.show">
              <el-input
                clearable
                size="mini"
                v-model="filterForm.phone"
                :placeholder="columns.phone.title"
              >
              </el-input>
            </th>
            <th v-if="columns.polMW.show">
              <el-input
                clearable
                size="mini"
                v-model="filterForm.polMW"
                :placeholder="columns.polMW.title"
              >
              </el-input>
            </th>
            <th v-if="columns.role_id.show">
              <el-select
                v-model="filterForm.role_id"
                filterable
                clearable
                :placeholder="columns.role_id.title"
                size="mini"
              >
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </th>
            <th v-if="columns.inn_cod.show">
              <el-input
                clearable
                size="mini"
                v-model="filterForm.inn_cod"
                :placeholder="columns.inn_cod.title"
              >
              </el-input>
            </th>
            <th v-if="columns.room_number.show">
              <el-input
                clearable
                size="mini"
                v-model="filterForm.room_number"
                :placeholder="columns.room_number.title"
              >
              </el-input>
            </th>
            <th v-if="columns.status.show">
              <el-select
                v-model="filterForm.status"
                filterable
                clearable
                :placeholder="columns.status.title"
                size="mini"
              >
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </th>
            <th v-if="columns.email.show">
              <el-input
                v-model="filterForm.email"
                clearable
                :placeholder="columns.email.title"
                size="mini"
              >
              </el-input>
            </th>
            <th v-if="columns.settings.show"></th>
          </tr>
        </thead>

        <transition-group name="flip-list" tag="tbody">
          <tr v-for="user in list" :key="user.id" class="cursor-pointer">
            <td v-if="columns.id.show">{{ user.id }}</td>
            <td v-if="columns.name.show">{{ user.name }}</td>
            <td v-if="columns.IDcod.show">{{ user.IDcod }}</td>
            <td v-if="columns.created_at.show">{{ user.created_at }}</td>
            <td v-if="columns.phone.show">{{ user.phone }}</td>
            <td v-if="columns.polMW.show">{{ user.polMW }}</td>
            <td v-if="columns.role_id.show">
              {{ user.role ? user.role.name : "" }}
            </td>
            <td v-if="columns.inn_cod.show">{{ user.inn_cod }}</td>
            <td v-if="columns.room_number.show">{{ user.room_number }}</td>
            <td v-if="columns.status.show">
              {{
                user.status === "active"
                  ? $t("message.active")
                  : $t("message.npt_active")
              }}
            </td>
            <td v-if="columns.email.show">{{ user.email }}</td>
            <td v-if="columns.settings.show" class="settings-td">
              <el-dropdown szie="mini">
                <el-button size="mini" icon="el-icon-setting" round>
                  <i class="el-icon-arrow-down"></i>
                </el-button>
                <el-dropdown-menu slot="dropdown" size="mini">
                  <el-dropdown-item
                    command="edit"
                    icon="el-icon-edit el-icon--left"
                  >
                    {{ $t("message.update") }}</el-dropdown-item
                  >
                  <el-dropdown-item
                    command="print"
                    icon="el-icon-printer el-icon--left"
                    >{{ $t("message.print") }}</el-dropdown-item
                  >
                  <!-- <el-dropdown-item v-if="permissions.includes(name+'.'+'show')" command="show"  icon="el-icon-view el-icon--left"> Показать</el-dropdown-item> -->
                  <el-dropdown-item
                    command="back_material"
                    icon="el-icon-refresh-left el-icon--left"
                    >{{ $t("message.return_material") }}</el-dropdown-item
                  >
                  <el-dropdown-item
                    command="comments"
                    icon="el-icon-chat-line-square el-icon--left"
                    >{{ $t("message.comment") }}</el-dropdown-item
                  >
                  <el-dropdown-item
                    command="delete"
                    icon="el-icon-delete el-icon--left"
                    >{{ $t("message.delete") }}</el-dropdown-item
                  >
                </el-dropdown-menu>
              </el-dropdown>
            </td>
          </tr>
        </transition-group>
      </table>

      <el-pagination
        class="mt-3 mypagination"
        background
        layout="prev, pager, next"
        :total="1000"
      >
      </el-pagination>

      <!--starting components modal  -->
      <el-drawer
        class="popups_title"
        :visible.sync="tabAddDoctor"
        direction="rtl"
        size="80%" :wrapperClosable="false"
      >
        <AddDoctor></AddDoctor>
      </el-drawer>
      <!--starting components modal  -->
    </div>
  </div>
</template>    
<script>
import AddDoctor from "./components/addDoctor.vue";
export default {
  data() {
    return {
      loadingData: false,
      tabAddDoctor: false,
      list: [
        {
          id: 1,
          name: "Azimov Abdulaxadxon Axmatxon o'g'li",
          IDcod: "IT000013",
          created_at: "2019-11-05",
          phone: "+998937307555",
          polMW: "Мужчин",
          role_id: "doctor",
          inn_cod: "91563223",
          room_number: 1,
          status: "active",
          email: "gomedInfo@mail.com",
        },
      ],
      filterForm: {
        search: "",
        id: "",
        name: "",
        created_at: "",
        phone: "",
        polMW: "",
        role_id: null,
        inn_cod: "",
        room_number: "",
        email: "",
      },
      columns: {
        id: {
          show: true,
          title: "№",
          sortable: true,
          column: "id",
        },
        name: {
          show: true,
          title: "Наименование",
          sortable: true,
          column: "name",
        },
        IDcod: {
          show: true,
          title: "ID Код",
          sortable: true,
          column: "IDcod",
        },
        created_at: {
          show: true,
          title: "Создан",
          sortable: true,
          column: "created_at",
        },
        phone: {
          show: true,
          title: "Телефон",
          sortable: true,
          column: "phone",
        },
        polMW: {
          show: true,
          title: "Пол",
          sortable: true,
          column: "polMW",
        },
        role_id: {
          show: false,
          title: "Роль",
          sortable: true,
          column: "role_id",
        },
        inn_cod: {
          show: false,
          title: "ИНН",
          sortable: true,
          column: "inn_cod",
        },
        room_number: {
          show: true,
          title: "Номер комнаты",
          sortable: true,
          column: "room_number",
        },
        status: {
          show: false,
          title: "Статус",
          sortable: true,
          column: "status",
        },
        email: {
          show: false,
          title: "Эл. адрес",
          sortable: true,
          column: "email",
        },
        settings: {
          show: true,
          title: "Настройки",
          sortable: false,
          column: "settings",
        },
      },
      options: [
        {
          value: "Option1",
          label: "Option1",
        },
        {
          value: "Option2",
          label: "Option2",
        },
        {
          value: "Option3",
          label: "Option3",
        },
        {
          value: "Option4",
          label: "Option4",
        },
        {
          value: "Option5",
          label: "Option5",
        },
      ],
    };
  },
  components: {
    AddDoctor,
  },
};
</script>
