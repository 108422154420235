<template>
  <div>
    <div class="modal_header">
      <el-row>
        <el-col :xs="24" :sm="24">
          <div class="grid-content bg-purple">
            <div class="title_h">Новый пользователь</div>
          </div>
        </el-col>
        <el-col :xs="24" :sm="24">
          <div class="grid-content bg-purple-light">
            <el-row class="button_r">
              <el-button type="success">{{$t('message.save_and_close')}}</el-button>
              <el-button>{{$t('message.close')}}</el-button>
            </el-row>
          </div>
        </el-col>
      </el-row>
    </div>

    <el-form ref="form" :model="form" class="aticler_m">
      <el-row :gutter="20">
        <el-col :span="8">
          <div class="grid-content">
            <div class="imgUpload">
              <el-upload
                action="https://jsonplaceholder.typicode.com/posts/"
                list-type="picture-card"
                :on-preview="handlePictureCardPreview"
                :on-remove="handleRemove"
              >
                <i class="el-icon-plus"></i>
              </el-upload>
              <el-dialog :visible.sync="dialogVisible">
                <img width="100%" :src="dialogImageUrl" alt="" />
              </el-dialog>
            </div>

            <el-form-item label="Имя">
              <el-input v-model="form.name"></el-input>
            </el-form-item>
            <el-form-item label="Фамилария">
              <el-input v-model="form.familaria"></el-input>
            </el-form-item>
            <el-form-item label="Отчество">
              <el-input v-model="form.middleName"></el-input>
            </el-form-item>
          </div>
        </el-col>
        <!-- end col -->

        <el-col :span="8" class="mt14">
          <div class="grid-content">
            <el-form-item label="Эл. адрес">
              <el-input v-model="form.email" placeholder="mail@"></el-input>
            </el-form-item>
            <el-form-item label="Телефон 1">
              <el-input
                v-model="form.phonenumber1"
                placeholder="998(__) ___-__-__"
              ></el-input>
            </el-form-item>
            <el-form-item label="Телефон 2">
              <el-input
                v-model="form.phonenumber2"
                placeholder="998(__) ___-__-__"
              ></el-input>
            </el-form-item>
            <el-form-item label="Пароль">
              <el-input
                v-model="form.password"
                placeholder="Пожалуйста, введите пароль"
                show-password
              ></el-input>
            </el-form-item>
          </div>
        </el-col>
        <!-- end col -->

        <el-col :span="8" class="mt14">
          <div class="grid-content">
            <el-form-item label="Пол">
              <el-select v-model="form.floor" placeholder="Пол">
                <el-option label="Н/О" value="no"></el-option>
                <el-option label="Мужчин" value="men"></el-option>
                <el-option label="Женщин" value="women"></el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="ID Код">
              <el-input v-model="form.idcods" placeholder="ID Код"></el-input>
            </el-form-item>

            <el-form-item label="Номер комнаты">
              <el-select v-model="form.roomNumbe" placeholder="Номер">
                <el-option label="1" value="a1"></el-option>
                <el-option label="2" value="a2"></el-option>
                <el-option label="2" value="a2"></el-option>
                <el-option label="3" value="a2"></el-option>
                <el-option label="5" value="a2"></el-option>
              </el-select>
            </el-form-item>

            <div class="d-flex align-items-center">
              <el-form-item label="Роль">
                <el-select
                  v-model="form.role"
                  placeholder="пожалуйста, выберите рол"
                >
                  <el-option label="Admin" value="admin"></el-option>
                  <el-option label="Doctor" value="doctor"></el-option>
                </el-select>
              </el-form-item>
              <el-button
                class="minadd ml10"
                @click="tabAddRole = true"
                icon="el-icon-circle-plus-outline"
              ></el-button>
            </div>
          </div>
        </el-col>
        <!-- end col -->

        <el-col :span="24">
          <div class="grid-content">
            <el-tabs
              v-model="form.activetabs"
              @tab-click="handleClick"
              class="ifoData mb20"
              type="border-card"
            >
              <el-tab-pane label="Документ" name="first">
                <el-row :gutter="20">
                  <el-col :sm="12">
                    <div class="grid-content">
                      <el-form-item label="Документ">
                        <el-select v-model="form.document">
                          <el-option
                            label="наружная реклама"
                            value="shanghai"
                          ></el-option>
                          <el-option
                            label="Zone two"
                            value="beijing"
                          ></el-option>
                        </el-select>
                      </el-form-item>
                    </div>
                  </el-col>
                  <!-- end col -->
                  <el-col :sm="12">
                    <div class="grid-content">
                      <el-form-item label="Серия и номер ">
                        <el-input
                          v-model="form.seriesNumber"
                          placeholder="__ ____"
                        ></el-input>
                      </el-form-item>
                    </div>
                  </el-col>
                  <!-- end col -->
                  <el-col :sm="12">
                    <div class="grid-content">
                      <el-form-item label="ИНН номер">
                        <el-input
                          v-model="form.inn"
                          placeholder="_______"
                        ></el-input>
                      </el-form-item>
                    </div>
                  </el-col>
                  <!-- end col -->
                  <el-col :sm="12">
                    <div class="grid-content">
                      <el-form-item label="Дата рождения">
                        <el-date-picker
                          :format="'dd.MM.yyyy'"
                          :value-format="'dd.MM.yyyy'"
                          v-model="form.data2"
                          type="date"
                          placeholder="__.__.____"
                        >
                        </el-date-picker>
                      </el-form-item>
                    </div>
                  </el-col>
                  <!-- end col -->
                  <el-col :sm="12">
                    <div class="grid-content">
                      <el-form-item label="Орган выдачи ">
                        <el-input v-model="form.issuingAuthority"></el-input>
                      </el-form-item>
                    </div>
                  </el-col>
                  <!-- end col -->
                  <el-col :sm="12">
                    <el-upload
                      class="upload-demo myupload-demoR"
                      action="https://jsonplaceholder.typicode.com/posts/"
                      :on-preview="handlePreview"
                      :on-remove="handleRemove"
                      :file-list="fileList"
                      list-type="picture"
                    >
                      <el-button size="small" type="primary"
                        >Click to upload</el-button
                      >
                      <!-- <div slot="tip" class="el-upload__tip">jpg/png files with a size less than 500kb</div> -->
                    </el-upload>
                  </el-col>
                  <!-- end col -->
                </el-row>
                <!-- end row -->
              </el-tab-pane>
              <el-tab-pane label="Адрес регистрации" name="second">
                <el-row :gutter="20">
                  <el-col :sm="12">
                    <div class="grid-content">
                      <el-form-item label="Город">
                        <el-select v-model="form.city">
                          <el-option
                            label="наружная реклама"
                            value="shanghai"
                          ></el-option>
                          <el-option
                            label="Zone two"
                            value="beijing"
                          ></el-option>
                        </el-select>
                      </el-form-item>
                    </div>
                  </el-col>
                  <!-- end col -->

                  <el-col :sm="12">
                    <div class="grid-content">
                      <el-form-item label="Регион">
                        <el-input v-model="form.region"></el-input>
                      </el-form-item>
                    </div>
                  </el-col>
                  <!-- end col -->

                  <el-col :sm="12">
                    <div class="grid-content">
                      <el-form-item label="Улица">
                        <el-input v-model="form.street"></el-input>
                      </el-form-item>
                    </div>
                  </el-col>
                  <!-- end col -->

                  <el-col :sm="12">
                    <div class="grid-content">
                      <el-form-item label="Район">
                        <el-input v-model="form.area"></el-input>
                      </el-form-item>
                    </div>
                  </el-col>
                  <!-- end col -->

                  <el-col :span="4">
                    <div class="grid-content">
                      <el-form-item label="Дом">
                        <el-input v-model="form.house"></el-input>
                      </el-form-item>
                    </div>
                  </el-col>
                  <!-- end col -->

                  <el-col :span="4">
                    <div class="grid-content">
                      <el-form-item label="Корпус">
                        <el-input v-model="form.body"></el-input>
                      </el-form-item>
                    </div>
                  </el-col>
                  <!-- end col -->

                  <el-col :span="4">
                    <div class="grid-content">
                      <el-form-item label="Квартира">
                        <el-input v-model="form.flat"></el-input>
                      </el-form-item>
                    </div>
                  </el-col>
                  <!-- end col -->
                </el-row>
                <!-- end row -->
              </el-tab-pane>
            </el-tabs>
          </div>
        </el-col>
        <!-- end col -->
      </el-row>
      <!-- end row -->
    </el-form>
    <!-- end form -->

    <!--starting components modal  -->
    <el-drawer
      class="popups_title"
      :append-to-body="true"
      :visible.sync="tabAddRole"
      direction="rtl"
      size="75%" :wrapperClosable="false"
    >
      <AddRole></AddRole>
    </el-drawer>
    <!--starting components modal  -->
  </div>
</template>

<script>
import AddRole from "./addRole.vue";

export default {
  data() {
    return {
      tabAddRole: false,
      form: {
        name: "",
        familaria: "",
        middleName: "",
        email: "",
        phonenumber1: "",
        phonenumber2: "",
        roomNumbe: "",
        floor: "",
        idcods: "",
        password: "",
        role: "",

        activetabs: "first",
        document: "",
        seriesNumber: "",
        inn: "",
        data2: "",
        issuingAuthority: "",
        city: "",
        region: "",
        street: "",
        area: "",
        house: "",
        body: "",
        flat: "",
      },
      fileList: [
        {
          name: "food.jpeg",
          url: "https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100",
        },
      ],
      dialogImageUrl: "",
      dialogVisible: false,
    };
  },
  methods: {
    handleClick(tab, event) {
      console.log(tab, event);
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePreview(file) {
      console.log(file);
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    handleAvatarSuccess(res, file) {
      this.imageUrl = URL.createObjectURL(file.raw);
    },
  },
  components: {
    AddRole,
  },
};
</script> 

